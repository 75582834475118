/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, NgZone } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { take } from "rxjs/operators";
import { CommonUtils } from "src/app/common/utils/common-util";
import { environment } from "src/environments/environment";
import { ElectronService } from "src/app/services/electron.service";
import { ConfigService } from "src/app/config.service";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { NotificationService } from "src/app/services/notification.service";

@Component({
  selector: "vp-version-dialog",
  templateUrl: "./version-dialog.component.html"
})
export class VersionDialogComponent {
  app_version: string = "1.0.1.352";
  appLogo: string;
  aboutText: string;
  changeLog: string;
  prefixBold: string;
  suffixNormal: string;
  isVNCFluidBuild = environment.isVNCFluidBuild;
  backgroundImage = !this.isVNCFluidBuild ? "url('assets/images/login-background.png')" : "url('assets/images/Default-736h.png')";

  constructor(
    public dialogRef: MatDialogRef<VersionDialogComponent>,
    private broadcaster: Broadcaster,
    private ngZone: NgZone,
    private electronService: ElectronService,
    private configService: ConfigService,
    private notificationService: NotificationService
  ) {
    this.prefixBold = "VNC";
    this.suffixNormal = "commander";
    this.broadcaster.on<any>(BroadcastKeys.HIDE_VERSION_DIALOG).pipe(take(1)).subscribe(res => {
      this.ngZone.run(() => {
        this.close();
      });
    });
    if (environment.isCordova) {
      document.addEventListener("deviceready", this.deviceReady.bind(this), false);
    }
    if (this.electronService.isElectron) {
      this.app_version = this.electronService.appVersion;
    }
    this.appLogo = environment.aboutUsLogo;
    this.aboutText = environment.aboutText;
    if (this.configService.URLS.changeLog) {
      this.changeLog = this.configService.URLS.changeLog;
    } else {
      this.changeLog = "https://redmine.vnc.biz/projects/changelog/wiki/VNCmail";
    }
  }

  deviceReady() {
    try {
      cordova.getAppVersion.getVersionNumber().then(versionNumber => {

        if (CommonUtils.isOnAndroid()) {
          // for Android we use ${VERSION}.${env.BUILD_NUMBER} as a version
          // so do not need to pass a build number since version contains both.
          this.app_version = versionNumber;

        } else {
          cordova.getAppVersion.getVersionCode().then(buildNumber => {

            this.app_version = versionNumber + "." + buildNumber;

          });
        }
      });
    } catch (e) {

    }
  }


  close() {
    this.dialogRef.close();
  }

  copyToClipboard() {
    CommonUtils.copyToClipboard([`V ${this.app_version}`]);
    this.notificationService.openSnackBarWithTranslation("COPIED_TO_CLIPBOARD", {}, 2000);
  }
}
