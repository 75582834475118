/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule, TranslatePipe } from "@ngx-translate/core";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { CdkTreeModule } from "@angular/cdk/tree";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule, } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatTreeModule } from "@angular/material/tree";
import { PlatformModule } from "@angular/cdk/platform";
import { UserAvatarComponent } from "./components/user-avtar/user-avatar.component";
import { FormatFileSizePipe } from "./pipes/format-file-size.pipe";
import { ScrollListDirective, VNCLongPressDirective } from "./directives";
import { DebounceClickDirective } from "./directives/debound-click.directive";
import { SafeHtmlPipe } from "./pipes/safe-html.pipe";
import { AppsDialogComponent } from "./components/apps-dialog/apps-dialog.component";
import { AppSwitcherComponent } from "./components/app-switcher";
import { FlexLayoutModule } from "@angular/flex-layout";
import { DisplayTimePipe } from "./pipes/displayTime.pipe";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { CustomSpinnerComponent } from "./components/custom-spinner/custom-spinner.component";
import { VersionDialogComponent } from "./components/version-dialog/version-dialog.component";
import { GeneralSettingsDialogComponent } from "./components/general-settings-dialog/general-settings-dialog.component";
import { ImageCropperModule } from "ngx-image-cropper";
import { VNCEmojiComponent } from "./components/emoji";
import { AutoSizeDirective } from "./directives/autosize.directive";
import { MentionModule } from "./directives/mention";
import { QuillModule } from "ngx-quill";
import { TalkAutocompleteComponent } from "./components/talk-autocomplete/autocomplete.component";
import { MailAutocompleteComponent } from "./components/mail-autocomplete/mail-autocomplete.component";
import {
  GeneralConfirmationDialogComponent
} from "src/app/shared/components/general-confirmation-dialog/general-confirmation-dialog.component";
import { MailOperationButtonComponent } from "src/app/shared/components/mail-operation-button/mail-operation-button.component";
import { MailAttachmentComponent } from "src/app/shared/components/mail-attachment/mail-attachment.component";
import { DragDropDirective } from "src/app/shared/directives/drag-drop-upload.directive";
import { ResizableDirective } from "./directives/resizable.directive";
import { GetFullNamePipe } from "./pipes/get-fullname.pipe";
import { AttachEmailDialogComponent } from "src/app/shared/components/attach-email-dialog/attach-email-dialog.component";
import { MobileSettingsDialogComponent } from "src/app/shared/components/mobile-settings-dialog/mobile-settings-dialog.component";
import { MobileHelpDialogComponent } from "src/app/shared/components/mobile-help-dialog/mobile-help-dialog.component";
import { LegalNoticeDialogComponent } from "src/app/shared/components/legal-notice-dialog/legal-notice-dialog.component";
import { MessageActionsComponent } from "./components/message-actions/message-actions.component";
import { LocaleDatePipe } from "./pipes/locale-date.pipe";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { MobilePreferencesDialogComponent } from "src/app/shared/components/mobile-preferences-dialog/mobile-preferences-dialog.component";
import { VNCButtonComponent } from "./components/button/button.component";
import { VNCDatePickerComponent } from "./components/vp-datepicker/datepicker.component";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { MobileTagsDialogComponent } from "./components/mobile-tags-dialog/mobile-tags-dialog.component";
import { MobileSearchesDialogComponent } from "./components/mobile-searches-dialog/mobile-searches-dialog.component";
import { VNCInputComponent } from "./components/input/input.component";
import { VNCSelectComponent } from "./components/select/select.component";
import { VNCCustomTooltipComponent } from "./components/custom-tooltip/custom-tooltip.component";
import { ToolTipRendererDirective } from "./directives/custom-tooltip.directive";
import { MobileFiltersDialogComponent } from "./mobile-filters-dialog/mobile-filters-dialog.component";
import { VncLibraryModule, VNCNoDataModule, SearchBarService, TopBarService } from "vnc-library";
import { SafeTrustURL } from "./pipes/safe-trust-url.pipe";

const ANGULAR_MATERIALS = [
  CdkTreeModule,
  DragDropModule,
  MatBadgeModule,
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  // MatNativeDateModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatTabsModule,
  MatToolbarModule,
  MatTreeModule,
  ScrollingModule,
  MatProgressSpinnerModule,
  MatTooltipModule,
  MatMenuModule
];

const OTHER_COMMON_MODULES = [
  FlexLayoutModule,
  QuillModule,
  FormsModule,
  ReactiveFormsModule,
  TranslateModule,
  MentionModule,
  VncLibraryModule,
  VNCNoDataModule,
  NgxMatSelectSearchModule
];

const DIRECTIVES = [
  ScrollListDirective,
  VNCLongPressDirective,
  DebounceClickDirective,
  AutoSizeDirective,
  ResizableDirective,
  DragDropDirective,
  ToolTipRendererDirective,
];

const DIALOGUES = [
  AppsDialogComponent,
  VersionDialogComponent,
  GeneralSettingsDialogComponent,
  GeneralConfirmationDialogComponent,
  MobileSettingsDialogComponent,
  MobileTagsDialogComponent,
  MobileSearchesDialogComponent,
  MobileHelpDialogComponent,
  LegalNoticeDialogComponent,
  AttachEmailDialogComponent,
  MobilePreferencesDialogComponent,
  MobileFiltersDialogComponent,
];

const PIPES = [
  FormatFileSizePipe,
  SafeHtmlPipe,
  DisplayTimePipe,
  GetFullNamePipe,
  LocaleDatePipe,
  SafeTrustURL
];

const COMPONENTS = [
  UserAvatarComponent,
  AppSwitcherComponent,
  CustomSpinnerComponent,
  VNCEmojiComponent,
  TalkAutocompleteComponent,
  MailAutocompleteComponent,
  MailOperationButtonComponent,
  MailAttachmentComponent,
  VNCButtonComponent,
  VNCDatePickerComponent,
  VNCInputComponent,
  VNCSelectComponent,
  VNCCustomTooltipComponent,
];

@NgModule({
    imports: [
        CommonModule,
        PlatformModule,
        ImageCropperModule,
        NgxDaterangepickerMd.forRoot({
            separator: " - ",
            applyLabel: "Okay",
        }),
        ...OTHER_COMMON_MODULES,
        ...ANGULAR_MATERIALS
    ],
    exports: [
        ...OTHER_COMMON_MODULES,
        ...ANGULAR_MATERIALS,
        // Directives
        ...DIRECTIVES,
        // Pipes
        ...PIPES,
        // Components
        ...COMPONENTS,
        // Dialogues
        ...DIALOGUES,
    ],
    declarations: [
        // Directives
        ...DIRECTIVES,
        // Components
        ...COMPONENTS,
        // Dialogues
        ...DIALOGUES,
        // Pipes
        ...PIPES,
        VNCLongPressDirective,
        UserAvatarComponent,
        FormatFileSizePipe,
        SafeHtmlPipe,
        AppSwitcherComponent,
        DisplayTimePipe,
        GetFullNamePipe,
        CustomSpinnerComponent,
        VNCEmojiComponent,
        TalkAutocompleteComponent,
        MailAutocompleteComponent,
        GeneralConfirmationDialogComponent,
        MailOperationButtonComponent,
        MailAttachmentComponent,
        AttachEmailDialogComponent,
        MessageActionsComponent,
        SafeTrustURL
    ],
    providers: [
        VNCLongPressDirective,
        ScrollListDirective,
        AutoSizeDirective,
        FormatFileSizePipe,
        DisplayTimePipe,
        GetFullNamePipe,
        TranslatePipe,
        DragDropDirective,
        SearchBarService,
        TopBarService
    ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
      ]
    };
  }
}
